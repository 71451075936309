<template>
<div class="ym-login-container" v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
  <login :title='title'
         :systemTitle="systemTitle"
         :align='align'
         :formStyle='formStyle'
         :options='options'
         :logoOptions='logoOptions'
         :background='background'
         :bottomText='bottomText'
         :forgetPassword="forgetPassword"
         :goRegist="goRegist"
         @onLogin="showlogin"
         @chengeImgVerify="chengeImgVerify"
         @sendPhoneMessage='sendPhoneMessage'
         ref="login"
         :btnTitle="'初始化平台'"
         >
         <div class="check-wrap" slot="check">
         </div>
  </login>
  </div>
</template>

<script>
import login from '../../components/Login'

// 本地存储工具类
import { _local, _session } from '../../utils/localStorage'
import axios from '@/plugins/axios'

export default {
  data() {
    return {
      timer: null,
      // 标题,显示在登录表单上方
      title: '设置超管账号',
      systemTitle: '欢迎使用兑欢券平台',
      // 登录框位置,可选 left/center/right
      align: 'center',
      // 表单风格, default默认无风格/icon图标风格/label标签风格
      formStyle: 'default',
      // 底部栏信息
      bottomText: '粤公网安备 44011202000383号',
      // 登录页背景,可以为图片,也可以传输颜色, 颜色必须以'#'开头
      background: {
        'background-color': '#F4F5F8',
        'background-image': 'url(' + require('../../components/Login/img/bg_login_img.jpg') + ')',
        'background-size': 'cover'
      },

      // 是否显示表单下面的前往登录链接
      goRegist: false,
      forgetPassword: false,
      loading: false,

      // 左上角logo配置项
      logoOptions: {
        left: '10px',
        logoType: 'img',
        title: 'YIMI蚁米',
        width: '180px',
        url: require('../../components/Layout/img/logo.png')
      },

      // 登录表单项详细配置,不传入则全部采用默认设置
      options: {
        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          mapping: 'account',
          // 账号栏label名,显示在输入框上方
          label: '用户名',
          // 账号栏左侧图标,需要formStyle为icon
          icon: 'el-icon-user',
          clearable: true,
          placeholder: '请输入用户名',
          message: '请输入用户名',
          rule: [
            { message: '请输入用户名', required: true },
            { message: '请输入20以内的（数字，字母，中文）',
              required: true,
              pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{1,20}$/
            }
          ]
          // 账号输入框表单校验正则,作为登录操作以及第一次点击登录后的校验依据
          // 开启校验后,若校验未通过,则在输入框下方进行红字提示
        },

        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          label: '密码',
          icon: 'el-icon-lock',
          clearable: true,
          placeholder: '请输入密码',
          message: '请输入密码',
          rule: [
            { message: '请输入', required: true },
            { message: '6到16位（字母，数字组合）', pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/, required: true }
          ]
        }
      }
    }
  },
  components: {
    login
  },
  methods: {
    checkInit () {
      return this.$http.user.checkInit()
    },
    // 执行登录操作,并将回调的账号保存在本地
    async showlogin(account, password, phoneVerify, imgVerify) {
      const { data } = await this.checkInit()
      if (!data.data) {
        this.$message.error('已有平台账号，请不要重复初始化')
        _local.set('hasInit', 1)
        _session.set('hasInit', 1)
        this.$router.push('/login')
        this.$refs.login.resetButton()
        return false
      }
      this.loading = true
      this.$http.user.init({
        account, password
      }).then(res => {
        if (res.data.code === 0) {
          _local.set('hasInit', 1)
          _session.set('hasInit', 1)
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.$router.push('/login')
          }, 2000)
        } else {
          this.loading = false
          this.$message.error(res.data.message)
          this.$refs.login.resetButton()
        }
      }).catch(err => {
        this.$message.error(err)
        this.loading = false
        this.$refs.login.resetButton()
      })
    },

    // 更换验证码回调方法,传入图片路径即可更换验证码
    chengeImgVerify() {
      console.log('更换验证码')
      this.options.imgVerify.url = 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1114443007,4076857238&fm=26&gp=0.jpg'
    },
    // 发送手机验证码事件
    sendPhoneMessage() {
      console.log('发送验证码')
    },
    hiddenModal () {
      this.modalShow = false
    },
    submit () {
      this.modalShow = false
    },
    modalShowFn (str) {
      this.modalTitle = str
      this.modalShow = !this.modalShow
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      axios.get(window.BASE_URL + '/init/checkInit').then(res => {
        if (res.data.code === 0) {
          if (res.data.data) {
            if (to.path === '/init') {
              next()
            } else {
              next('/init')
            }
          } else {
            next('/login')
          }
        }
      }).catch(err => {
        console.log(err)
      })
    })
  }
}
</script>

<style lang="scss">
.ym-login-container{
  width:100%;
  height: 100%;
}

.check-wrap{
  width: 70%;
  margin: 20px 15%;
  display: flex;
  align-items: center;
  .check-text{
    font-size: $--font-size-extra-small;
    color: $--color-text-primary;
    i {
      font-style: normal;
      color: $--color-primary;
      cursor: pointer;
    }
  }
  .icon-wrap{
    width: 16px;
    height:16px;
    border-radius: 50%;
    color: $--icon-color2;
    margin-right: 8px;
    cursor: pointer;
     i{
       font-size:16px;
     }
  }
  .active{
    color: $--color-primary;
  }
}

</style>
